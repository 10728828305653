import { Validate } from 'react-hook-form/dist/types/validator';
import validator from 'validator';

import { isDefined } from '@/utils/types';

import { Translator } from './translations/translationTypes';

import isUUID = validator.isUUID;

export const validateRules = <T>(...validators: Array<Validate<T> | undefined>) => {
  return {
    validate: (value: T) => {
      const failedRule = validators.filter(isDefined).find((item) => item(value) !== undefined);
      return failedRule ? failedRule(value) : undefined;
    },
  };
};

export const requiredRule = (t: Translator): Validate<unknown> => {
  return (value) => {
    return isDefined(value) && value !== '' ? undefined : t('msg_validation_required');
  };
};

export const uniqueRule = (t: Translator, compareValue: string): Validate<string> => {
  return (value) => {
    return value !== compareValue ? undefined : t('msg_validation_field_not_unique');
  };
};

export const isUuidRule = (t: Translator): Validate<string> => {
  return (value) => {
    return isUUID(value) ? undefined : t('msg_validation_bad_UUID');
  };
};
