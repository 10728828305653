import { css } from '@emotion/react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import AuthenticationTokenForm from '@/components/AuthenticationTokenForm';
import { RouteConfig } from '@/config/routes';
import { Breakpoint, ColorPalette, text } from '@/config/style';
import { useAppInfo } from '@/hooks/useAppInfo';
import { useTranslation } from '@/hooks/useTranslation';
import { ReactComponent as Logo } from '@/images/icons/spike-logo.svg';

const LoginPage = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const params = new URLSearchParams(location.search);
  const appInfo = useAppInfo();
  const navigate = useNavigate();

  const isAuthenticated = !!appInfo.appToken && !!appInfo.appId;

  useEffect(() => {
    if (isAuthenticated) {
      navigate(RouteConfig.Application.buildLink());
    }
  }, [isAuthenticated, navigate]);

  if (isAuthenticated) {
    return null;
  }

  return (
    <div css={styles.container}>
      <div css={styles.filler}>
        <Logo css={styles.desktopLogo}></Logo>
      </div>
      <div css={styles.contentWrapper}>
        <div css={styles.content}>
          <Logo css={styles.mobileLogo}></Logo>
          <h1 css={styles.title}>{t('msg_login_title')}</h1>
          <AuthenticationTokenForm
            defaultValues={{ appToken: params.get('app_token') || '', appId: params.get('app_id') || '' }}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

const styles = {
  container: css`
    width: 100%;
    height: 100%;
    display: flex;
  `,

  filler: css`
    width: 50%;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;

    ${Breakpoint.mobile} {
      display: none;
    }
  `,

  contentWrapper: css`
    width: 50%;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${ColorPalette.blue_700};

    ${Breakpoint.mobile} {
      width: 100%;
    }
  `,

  content: css`
    display: flex;
    flex-direction: column;
    width: 70%;
  `,

  title: css`
    ${text.h1};
    color: ${ColorPalette.white};
    margin-bottom: 30px;

    ${Breakpoint.mobile} {
      ${text.h2};
      margin-bottom: 40px;
    }
  `,

  desktopLogo: css`
    width: 70%;
    height: 70%;
  `,

  mobileLogo: css`
    width: 70%;
    height: auto;
    fill: ${ColorPalette.white};
    display: none;

    ${Breakpoint.mobile} {
      display: flex;
      align-self: center;
    }

    ${Breakpoint.mobile} {
      width: 100%;
    }
  `,
};
