import { css } from '@emotion/react';
import React, { useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Button from '@/components/Buttons/Button';
import SidebarButton from '@/components/Buttons/SidebarButton';
import { RouteConfig } from '@/config/routes';
import { Breakpoint, ColorPalette, zIndex } from '@/config/style';
import { useTranslation } from '@/hooks/useTranslation';
import { ReactComponent as ExternalArrow } from '@/images/icons/arrow-NE.svg';
import { ReactComponent as BurgerMenu } from '@/images/icons/burger.svg';
import { ReactComponent as Logo } from '@/images/icons/spike-logo.svg';
import { Translator } from '@/utils/translations/translationTypes';

// @TODO: add temporarily removed tabs: existing_integrations, existing_users
const INTERNAL_SIDEBAR_TABS = ['application'] as const;
const EXTERNAL_SIDEBAR_TABS = [{ key: 'datahub', link: 'https://datahub.console.spikeapi.com/' }] as const;

type SidebarTab = (typeof INTERNAL_SIDEBAR_TABS)[number];

const internalRouteMap: Record<SidebarTab, string> = {
  application: RouteConfig.Application.buildLink(),
  // existing_integrations: RouteConfig.ExistingIntegrations.buildLink(),
  // existing_users: RouteConfig.ExistingUsers.buildLink(),
};

const getInternalTabs = (t: Translator) =>
  INTERNAL_SIDEBAR_TABS.map((key) => ({ label: t(`msg_siderbar_tab_${key}`), key }));

const getExternalTabs = (t: Translator) =>
  EXTERNAL_SIDEBAR_TABS.map(({ key, link }) => ({ label: t(`msg_siderbar_tab_${key}`), key, link }));

const Sidebar = ({ onSidebarToggle }: { onSidebarToggle: () => void }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const internalTabs = useMemo(() => getInternalTabs(t), [t]);
  const externalTabs = useMemo(() => getExternalTabs(t), [t]);

  return (
    <aside css={styles.container}>
      <div css={styles.topRow}>
        <div css={styles.sidebarToggle}>
          <Button size="icon" variant="transparent" onClick={() => onSidebarToggle()}>
            <BurgerMenu style={{ fontSize: '24px', fill: 'white' }} />
          </Button>
        </div>
        <Logo css={styles.logo} />
      </div>
      <div css={styles.bottomRow}>
        {internalTabs.map(({ label, key }) => (
          <SidebarButton
            key={key}
            children={label}
            onClick={() => navigate(internalRouteMap[key])}
            active={!!pathname.match(internalRouteMap[key])}
          />
        ))}
        {!!externalTabs.length && <hr css={styles.divider}></hr>}
        {externalTabs.map(({ label, key, link }) => (
          <Link to={link} key={key} target="_blank">
            <SidebarButton>
              {label} <ExternalArrow fill={ColorPalette.white} />
            </SidebarButton>
          </Link>
        ))}
      </div>
    </aside>
  );
};

export default Sidebar;

const styles = {
  container: css`
    background-color: ${ColorPalette.blue_700};
    height: 100%;
    z-index: ${zIndex.sidebar};

    ${Breakpoint.mobile} {
      position: absolute;
      width: 100%;
    }
  `,

  topRow: css`
    display: flex;
    height: 95px;
    align-items: center;
    justify-content: center;

    ${Breakpoint.mobile} {
      padding: 0 20px;
      justify-content: space-between;
      height: 70px;
    }
  `,

  sidebarToggle: css`
    display: none;

    ${Breakpoint.mobile} {
      display: revert;
    }
  `,

  bottomRow: css`
    padding-top: 20px;
    width: 90%;

    display: flex;
    flex-direction: column;
    gap: 20px;
  `,

  logo: css`
    width: 160px;
    height: 40px;
    fill: ${ColorPalette.white};
  `,

  divider: css`
    width: 100%;
    margin-left: 15px;
    border: none;
    border-bottom: 1px solid ${ColorPalette.white};
  `,
};
