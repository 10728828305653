import i18n from 'i18next';

import OgDefaultImage from '@/images/ogImage.png';
import { getInternalFullUrl } from '@/utils/urls';

export enum LocalStorage {
  userId = 'userId',
  nativeUserId = 'nativeUserId',
  appToken = 'appToken',
  appId = 'appId',
  latestAppId = 'latestAppId',
}

export const META_DEFAULTS = {
  title: i18n.t('msg_default_meta_title') || '',
  description: i18n.t('msg_default_meta_description') || '',
  type: 'website',
  url: window.location.origin,
  image: getInternalFullUrl(OgDefaultImage),
};
