import { AxiosResponse } from 'axios';

import { useAuthenticatedDataMeQuery } from '@/services/base';
import { PaginatedResult } from '@/utils/types';

export interface ExistingUser {
  application: string;
  uuid: string;
  created_at: string;
  updated_at: string;
  user_id: string;
}

const mockPaginatedExistingUsers: PaginatedResult<ExistingUser> = {
  count: 50,
  limit: 3,
  offset: 0,
  totalPages: 1,
  currentPage: 1,
  results: [
    {
      application: 'Skye Health Mock app',
      uuid: '8e52ea49-0bef-4cd0-97cb-df30a7559a4a',
      created_at: 'Feb. 23, 2023, 11:44 a.m.',
      updated_at: 'Feb. 24, 2023, 11:44 a.m.',
      user_id: 'vp06q2JVua',
    },
    {
      application: 'Mock App2',
      uuid: '8e52ea49-0bef-4cd0-97cb-df30a7559a4b',
      created_at: 'Feb. 23, 2023, 11:44 a.m.',
      updated_at: 'Feb. 24, 2023, 11:44 a.m.',
      user_id: 'vp06q2JVua',
    },
    {
      application: 'Mock App3',
      uuid: '8e52ea49-0bef-4cd0-97cb-df30a7559a4c',
      created_at: 'Feb. 23, 2023, 11:44 a.m.',
      updated_at: 'Feb. 24, 2023, 11:44 a.m.',
      user_id: 'vp06q2JVua',
    },
  ],
};

interface UserQueryParams {
  search: string;
  ordering?: string;
  page: number;
  pageSize: number;
}

// @TODO: pass UserQueryParams to request params
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fetchExistingUsers = (params: UserQueryParams) => {
  return new Promise<AxiosResponse<PaginatedResult<ExistingUser>>>((resolve) => {
    setTimeout(
      () => resolve({ data: mockPaginatedExistingUsers } as AxiosResponse<PaginatedResult<ExistingUser>>),
      420,
    );
  });
};

export const useExistingUsersQuery = (params: UserQueryParams) => {
  return useAuthenticatedDataMeQuery({
    queryKey: ['ExistingUsers'],
    queryFn: () => fetchExistingUsers(params).then((res) => res.data),
  });
};
