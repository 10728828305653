import { css } from '@emotion/react';
import React from 'react';

import Button from '@/components/Buttons/Button';
import Select from '@/components/Form/Select';
import { Breakpoint, text } from '@/config/style';
import { usePagination } from '@/hooks/usePagination';
import { useTranslation } from '@/hooks/useTranslation';
import { ReactComponent as ArrowLeftIcon } from '@/images/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '@/images/icons/arrow-right.svg';
import { isNumber } from '@/utils/types';

const PAGE_SIZES = [20, 50, 100];

interface PaginatorProps {
  currentPage: number;
  pageSize: number;
  totalRows: number | undefined;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  adjustable?: boolean;
}

const Paginator = ({
  currentPage,
  onPageChange,
  totalRows = 0,
  pageSize,
  onPageSizeChange,
  adjustable = true,
}: PaginatorProps) => {
  const { t } = useTranslation();

  const pageCount = Math.max(Math.ceil(totalRows / pageSize), 1);

  const pages = usePagination({
    currentPage,
    totalRows,
    pageSize,
  });

  const previousDisabled = !currentPage;
  const nextDisabled = currentPage + 1 >= pageCount;
  const showPages = pageCount > 1;

  return (
    <div css={styles.container(adjustable, showPages)}>
      {showPages && (
        <div css={styles.pageNavigatorContainer}>
          <Button
            size="icon"
            variant="transparent"
            disabled={previousDisabled}
            onClick={() => {
              onPageChange(currentPage - 1);
            }}
          >
            <ArrowLeftIcon width={20} height={20} />
          </Button>
          {pages?.map((page, key) =>
            isNumber(page) ? (
              <Button
                key={key}
                size="icon"
                variant={currentPage === page ? 'secondary' : 'transparent'}
                children={page + 1}
                onClick={() => onPageChange(page)}
              />
            ) : (
              <span key={key} css={styles.dots}>
                {page}
              </span>
            ),
          )}
          <Button
            size="icon"
            variant="transparent"
            disabled={nextDisabled}
            onClick={() => {
              onPageChange(currentPage + 1);
            }}
          >
            <ArrowRightIcon width={20} height={20} />
          </Button>
        </div>
      )}
      {adjustable && (
        <div css={styles.pageSizeContainer}>
          <span css={text.m}>{t('msg_pagination_page_size_description')}</span>
          <Select
            size="small"
            name="page_size"
            options={PAGE_SIZES.map((value) => ({
              value,
              label: t('msg_pagination_page_size_input_value', { count: value }),
            }))}
            value={pageSize}
            onChange={(newValue) => {
              onPageChange(0);
              onPageSizeChange(newValue || PAGE_SIZES[0]);
            }}
            clearable={false}
          />
        </div>
      )}
    </div>
  );
};

const styles = {
  container: (adjustable?: boolean, showPages?: boolean) => css`
    display: flex;
    justify-content: space-between;
    gap: 20px;

    ${!adjustable &&
    css`
      justify-content: center;
    `}

    ${!showPages &&
    css`
      justify-content: flex-end;
    `}

    ${Breakpoint.mobile} {
      flex-direction: column;
    }

    ${Breakpoint.mobile} {
      flex-direction: revert;
      gap: 10px;
    }

    ${Breakpoint.smallMobile} {
      flex-direction: column;
      align-items: center;
    }
  `,
  pageNavigatorContainer: css`
    min-width: 190px;
    display: flex;
    align-items: center;
    gap: 10px;
  `,
  pageSizeContainer: css`
    display: flex;
    align-self: flex-end;
    align-items: center;
    gap: 10px;
    white-space: nowrap;
  `,
  dots: css`
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};

export default Paginator;
