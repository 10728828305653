import { css } from '@emotion/react';
import React, { useMemo, useState } from 'react';

import Input from '@/components/Form/Input';
import Paginator from '@/components/Table/Paginator';
import Table, { SortDirection } from '@/components/Table/Table';
import { createColumns } from '@/components/Table/utils';
import { Breakpoint, ColorPalette, fontWeight, helperStyles, text } from '@/config/style';
import { useSearch } from '@/hooks/useSearch';
import { useTranslation } from '@/hooks/useTranslation';
import { ReactComponent as SearchIcon } from '@/images/icons/search.svg';
import { ExistingUser, useExistingUsersQuery } from '@/services/user';
import { createOrderingParam } from '@/utils/query';

const ExistingUsersPage = () => {
  const { t } = useTranslation();
  const [currentSort, setCurrentSort] = useState<{ property: string; direction: SortDirection }>();
  const [paginationParams, setPaginationParams] = useState({ page: 0, pageSize: 20 });
  const { searchFor, searchValue, setSearchValue } = useSearch();
  const usersQuery = useExistingUsersQuery({
    search: searchFor.toLowerCase(),
    ordering: createOrderingParam(currentSort),
    ...paginationParams,
  });

  const columns = useMemo(
    () =>
      createColumns<ExistingUser>([
        {
          Header: t('msg_existing_users_table_application'),
          width: 130,
          sortProperty: 'application',
          accessor: (item) => item.application,
          Cell: (item) => <span css={styles.title}>{item.application}</span>,
        },
        {
          Header: t('msg_existing_users_table_uuid'),
          width: 150,
          truncateText: true,
          accessor: (item) => item.uuid,
        },
        {
          Header: t('msg_existing_users_table_created_at'),
          width: 130,
          sortProperty: 'created_at',
          accessor: (item) => item.created_at,
        },
        {
          Header: t('msg_existing_users_table_updated_at'),
          width: 130,
          sortProperty: 'updated_at',
          accessor: (item) => item.updated_at,
        },
        {
          Header: t('msg_existing_users_table_user_id'),
          width: 100,
          accessor: (item) => item.user_id,
        },
      ]),
    [t],
  );

  return (
    <div css={styles.container}>
      <div css={styles.search}>
        <Input
          value={searchValue}
          onChange={setSearchValue}
          name="search"
          size="small"
          placeholder="Search"
          prefix={<SearchIcon css={styles.searchIcon} />}
        />
      </div>
      <div css={[helperStyles.paper, styles.paperContent]}>
        <Table
          columns={columns}
          data={usersQuery.data?.results}
          loading={usersQuery.isInitialLoading}
          currentSort={currentSort}
          onSortChange={setCurrentSort}
        />
        <Paginator
          currentPage={paginationParams.page}
          onPageChange={(newPage) => {
            setPaginationParams((prev) => ({ ...prev, page: newPage }));
          }}
          totalRows={usersQuery.data?.count}
          pageSize={paginationParams.pageSize}
          onPageSizeChange={(newPageSize) => {
            setPaginationParams((prev) => ({ ...prev, pageSize: newPageSize }));
          }}
        />
      </div>
    </div>
  );
};

export default ExistingUsersPage;

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,
  table: css`
    width: 100%;
  `,
  title: css`
    ${text.m};
    font-weight: ${fontWeight.semiBold};
    color: ${ColorPalette.blue_700};
  `,
  search: css`
    width: 420px;

    ${Breakpoint.tablet} {
      width: 100%;
    }
  `,
  searchIcon: css`
    fill: ${ColorPalette.grey_200};
  `,
  paperContent: css`
    display: flex;
    flex-direction: column;
    gap: 30px;
  `,
};
