import { css } from '@emotion/react';
import React from 'react';

import Card from '@/components/Card';
import { LoadingSection } from '@/components/LoadingSpinner';
import NoData from '@/components/NoData';
import { Breakpoint } from '@/config/style';
import { useApplicationsQuery } from '@/services/application';

const ApplicationPage = () => {
  const applicationsQuery = useApplicationsQuery();

  if (applicationsQuery.isInitialLoading) {
    return <LoadingSection />;
  }

  if (!applicationsQuery.data) {
    return <NoData />;
  }

  return (
    <div css={styles.container}>
      {applicationsQuery.data.map((app, key) => (
        <Card key={key} {...app} />
      ))}
    </div>
  );
};

export default ApplicationPage;

const styles = {
  container: css`
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;

    ${Breakpoint.tablet} {
      grid-template-columns: 1fr;
    }

    ${Breakpoint.mobile} {
      grid-template-columns: 1fr 1fr;
    }

    ${Breakpoint.smallMobile} {
      grid-template-columns: 1fr;
      gap: 10px;
    }
  `,
};
