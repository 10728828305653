import { AxiosResponse } from 'axios';

import { LocalStorage } from '@/config/constants';
import { useAuthenticatedDataMeQuery } from '@/services/base';
import { getFromLocalStorage } from '@/utils/localStorage';

export interface Application {
  application: string;
  email: string;
  app_id: string;
  app_token: string;
  created_at: string;
  updated_at: string;
  user_id: string;
}

const mockApplications: Application[] = [
  {
    application: 'Demo app',
    email: '',
    app_id: 'f82c72bb-6824-4b0a-af6e-8c61c10aa086',
    app_token: '24892f83-8a3a-48bc-93cd-772a2cf99901',
    created_at: 'Feb. 23, 2023, 11:44 a.m.',
    updated_at: 'Feb. 24, 2023, 11:44 a.m.',
    user_id: 'vp06q2JVua',
  },
];

//@TODO replace data mocking when endpoint is ready
const fetchApplications = () => {
  return new Promise<AxiosResponse<Application[]>>((resolve) => {
    const appId = getFromLocalStorage(LocalStorage.appId);
    const appToken = getFromLocalStorage(LocalStorage.appToken);

    if (appId && appToken) {
      mockApplications[0].app_id = appId;
      mockApplications[0].app_token = appToken;
    }

    setTimeout(() => resolve({ data: mockApplications } as AxiosResponse<Application[]>), 420);
  });
};

export const useApplicationsQuery = () => {
  return useAuthenticatedDataMeQuery({
    queryKey: ['Applications'],
    queryFn: () => fetchApplications().then((res) => res.data),
  });
};
