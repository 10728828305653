import { useMutation, useQueryClient } from '@tanstack/react-query';

import Config from '@/config/config';
import { LocalStorage } from '@/config/constants';
import endpoints from '@/config/endpoints';
import authApiInstance from '@/utils/api';
import { getFromLocalStorage } from '@/utils/localStorage';
import { assertIsDefined } from '@/utils/types';
import { getUserId } from '@/utils/util';

import { useAuthenticatedDataMeQuery } from './base';

export const PROVIDERS = [
  'dexcom',
  'dexcom_sandbox',
  'fitbit',
  'garmin',
  'google_fit',
  'oura',
  'polar',
  'spotify',
  'strava',
  'whoop',
  'withings',
] as const;

export type Provider = (typeof PROVIDERS)[number];

export const METRICS = [
  'sleep',
  'heart',
  'glucose',
  'activities_stream',
  'activities_summary',
  'calories',
  'distance',
  'steps',
  'audio',
  'podcasts',
  'stress',
  'goals',
  'oxygen_saturation',
  'breathing',
] as const;

export type Metric = (typeof METRICS)[number];

export type UserIntegration = {
  provider: Provider;
  categories: {
    [key in Metric]: boolean;
  };
};

export interface DataQueryParams {
  startDate: string;
  endDate: string;
}

const authenticateProvider = (data: { provider: Provider }) => {
  const form = document.createElement('form');
  const providerInput = document.createElement('input');
  providerInput.value = data.provider;
  providerInput.name = 'provider';
  form.appendChild(providerInput);

  const clientIdInput = document.createElement('input');
  clientIdInput.value = getFromLocalStorage(LocalStorage.appId) || '';
  clientIdInput.name = 'client_id';
  form.appendChild(clientIdInput);

  const userIdInput = document.createElement('input');
  userIdInput.value = getUserId();
  userIdInput.name = 'user_id';
  form.appendChild(userIdInput);

  form.method = 'POST';
  form.action = `${Config.API_BASE_URL}${endpoints.INIT_AUTH}`;
  form.style.cssText = 'display: none';

  document.body.appendChild(form);

  form.submit();
  return Promise.resolve();
};

export const useAuthenticateProviderMutation = () => {
  return useMutation(authenticateProvider);
};

const fetchUserData = (userId: string | null) => {
  assertIsDefined(userId, 'userId is not defined. if you see this, you should unsee it');

  return authApiInstance.get<{
    client_user_id: string;
    integrations: UserIntegration[];
    user_id: string;
  }>(endpoints.USER(userId), {
    params: {},
  });
};

export const useUserQuery = (userId: string | null) => {
  return useAuthenticatedDataMeQuery({
    queryKey: ['UserDataQuery'],
    enabled: !!userId,
    queryFn: () => fetchUserData(userId).then((res) => res.data),
    onError: () => {},
  });
};

interface RemoveProviderParams {
  providers: Provider[];
  userId: string | null;
}

const removeProviders = ({ providers, userId }: RemoveProviderParams) => {
  assertIsDefined(userId, 'userId is not defined. if you see this, you should unsee it');

  return authApiInstance.post<Record<string, unknown>>(endpoints.RESET_INTEGRATIONS(userId), {
    integrations_to_remove: providers,
  });
};

export const useRemoveProvidersMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: RemoveProviderParams) => removeProviders(params).then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries(['UserDataQuery']);
    },
  });
};

const fetchDataForDisplay = (url: string, { startDate, endDate }: DataQueryParams) => {
  const appId = getFromLocalStorage(LocalStorage.latestAppId) || '';
  return authApiInstance.get<Record<string, unknown>>(url, {
    params: {
      start_date: startDate,
      end_date: endDate,
      user_id: getFromLocalStorage(`${appId}_userId`),
    },
  });
};

export const useDataDisplayQuery = (url: string, params: DataQueryParams) => {
  return useAuthenticatedDataMeQuery({
    queryKey: ['DataDisplayQuery', url, params],
    queryFn: () => fetchDataForDisplay(url, params).then((res) => res.data),
    onError: () => {},
  });
};
