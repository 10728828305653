import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import Header from '@/components/Header';
import { DESKTOP_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '@/components/Header';
import Sidebar from '@/components/Sidebar';
import { RouteConfig } from '@/config/routes';
import { Breakpoint, ColorPalette } from '@/config/style';
import { useAppInfo } from '@/hooks/useAppInfo';

const SIDEBAR_WIDTH = 300;

const PrivateLayout = () => {
  const appInfo = useAppInfo();
  const navigate = useNavigate();
  const [sidebarExpanded, setSidebarExpanded] = useState(false);

  const isAuthenticated = !!appInfo.appToken && !!appInfo.appId;

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(RouteConfig.Login.buildLink());
    }
  }, [isAuthenticated, navigate]);

  if (!isAuthenticated) {
    return null;
  }

  const handleToggleSidebar = () => setSidebarExpanded(!sidebarExpanded);

  return (
    <div css={styles.container}>
      <div css={styles.sidebar(sidebarExpanded)}>
        <Sidebar onSidebarToggle={handleToggleSidebar} />
      </div>
      <div css={styles.rightNode}>
        <Header onSidebarToggle={handleToggleSidebar} />
        <div css={styles.content}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default PrivateLayout;

const styles = {
  container: css`
    display: flex;
    height: 100%;

    ${Breakpoint.mobile} {
      grid-template-columns: auto;
    }
  `,

  sidebar: (expanded?: boolean) => css`
    position: fixed;
    height: 100%;
    width: ${SIDEBAR_WIDTH}px;

    ${Breakpoint.mobile} {
      display: none;
      position: absolute;

      ${expanded &&
      css`
        display: revert;
      `}
    }
  `,

  rightNode: css`
    padding-left: ${SIDEBAR_WIDTH}px;
    width: 100%;
    height: 100%;

    ${Breakpoint.mobile} {
      padding-left: revert;
    }
  `,

  content: css`
    padding: 30px;
    background-color: ${ColorPalette.white_100};
    min-height: calc(100% - ${DESKTOP_HEADER_HEIGHT}px);

    ${Breakpoint.mobile} {
      min-height: calc(100% - ${MOBILE_HEADER_HEIGHT}px);
      padding: 20px;
    }
  `,
};
