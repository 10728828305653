import { css } from '@emotion/react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/Buttons/Button';
import Input from '@/components/Form/Input';
import { LocalStorage } from '@/config/constants';
import { RouteConfig } from '@/config/routes';
import { useTranslation } from '@/hooks/useTranslation';
import { mapToInputProps } from '@/utils/form';
import { setToLocalStorage } from '@/utils/localStorage';
import { isUuidRule, requiredRule, uniqueRule, validateRules } from '@/utils/validation';

interface FormValues {
  appToken: string;
  appId: string;
}

const AuthenticationTokenForm = ({ defaultValues }: { defaultValues: FormValues }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { handleSubmit, control, watch } = useForm<FormValues>({
    defaultValues,
  });

  return (
    <form
      css={styles.inputContainer}
      onSubmit={handleSubmit((data) => {
        setToLocalStorage(LocalStorage.appId, data.appId);
        setToLocalStorage(LocalStorage.appToken, data.appToken);
        navigate(RouteConfig.Application.buildLink());
      })}
    >
      <Controller
        control={control}
        name="appId"
        rules={validateRules(requiredRule(t), uniqueRule(t, watch('appToken')), isUuidRule(t))}
        render={(controllerProps) => (
          <Input {...mapToInputProps(controllerProps)} placeholder={t('msg_field_app_id')} />
        )}
      />
      <Controller
        control={control}
        name="appToken"
        rules={validateRules(requiredRule(t), uniqueRule(t, watch('appId')), isUuidRule(t))}
        render={(controllerProps) => (
          <Input {...mapToInputProps(controllerProps)} placeholder={t('msg_field_app_token')} />
        )}
      />
      <Button variant="secondary" size="medium" type="submit">
        {t('msg_btn_save_token')}
      </Button>
    </form>
  );
};

export default AuthenticationTokenForm;

const styles = {
  inputContainer: css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 500px;
    width: 100%;
  `,
};
